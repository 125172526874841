import { useClientTranslation } from '@negotium/common';
import { IRecipients } from '@negotium/models';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

const useRegisterFormData = (
	initialValues: Partial<IRecipients>,
	onSubmit: (values: Partial<IRecipients>) => void,
	lng: string
): {
	formik: FormikProps<Partial<IRecipients>>;
	isLoading: boolean;
} => {
	const { t } = useClientTranslation(lng, ['validations']);

	let isLoading = false;
	const validationSchema = yup.object({
		name: yup.string().required(t('fieldRequired')),
		lastName: yup.string().required(t('fieldRequired')),
		phone: yup.string().required(t('fieldRequired')),
		email: yup.string().email(t('emailInvalid')).required(t('emailRequired')),
		ci: yup
			.string()
			.matches(/^\d{11}$/, t('ciInvalid'))
			.required(t('fieldRequired')),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values: Partial<IRecipients>) => {
			isLoading = true;
			await onSubmit(values);
			isLoading = false;
		},
	});
	return { formik, isLoading };
};

export default useRegisterFormData;
